import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";
import {
	ButtonSizes,
	ButtonVariantStyles,
	FONT_WEIGHT,
	TYPOGRAPHY,
	TextButtonVariantStyles,
} from "@/theme";

import ChevonDoubleVertical from "../../../public/assets/svg-icons/i-chevron-double-vertical.svg";

import type { StyledSelectProps } from "./types";

export const StyledLabel = styled.label<PropsWithTheme & StyledSelectProps>`
	${TYPOGRAPHY.buttonLabels.MD};
	align-items: center;
	cursor: pointer;
	display: inline-flex;
	font-weight: ${FONT_WEIGHT.medium};
	gap: 4px;
	justify-content: center;
	position: relative;
	width: 100%;
	${({ fullWidth, size = "XL", variant }) => css`
		${ButtonSizes[size]}
		${ButtonVariantStyles?.[variant] ?? TextButtonVariantStyles?.[variant]}
		padding: ${!!ButtonVariantStyles?.[variant] ? "10px 14px 9px 14px" : 0};
		width: ${fullWidth ? "100%" : "max-content"};

		&:hover {
			${ButtonVariantStyles?.[variant]?.hover ?? TextButtonVariantStyles?.[variant]?.hover}
		}

		&:active {
			${ButtonVariantStyles?.[variant]?.active ?? TextButtonVariantStyles?.[variant]?.active}
		}
	`};
`;

export const StyledSelect = styled.select`
	appearance: none;
	opacity: 0;
	background: transparent;
	border: 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

export const StyledChevron = styled(ChevonDoubleVertical)`
	pointer-events: none;
`;
