import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { FONT_WEIGHT } from "@/theme";
import { BadgeSizeStyles, BadgeVariantStyles } from "@/theme/badge";

import type { BadgeProps } from "./types";

export const Badge = styled.p<BadgeProps>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	border-radius: 60px;
	${({ size = "MD", variant = "primary", withShadow = false, theme: { tokens } }) => css`
		${BadgeSizeStyles[size]}
		${BadgeVariantStyles[variant]}
	font-weight: ${FONT_WEIGHT.medium};
		box-shadow: ${withShadow ? tokens.elevation.bottom.MD : "none"};
	`};
`;
