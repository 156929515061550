import type { ChangeEvent } from "react";
import React, { useEffect, useState } from "react";

import { Typography } from "@/components/typography/typography";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";

import { ButtonVariants } from "../buttons/types";

import { StyledChevron, StyledLabel, StyledSelect } from "./styled";
import type { SelectProps } from "./types";

export const Select = ({
	fullWidth,
	name,
	onSelect,
	options,
	placeholderText,
	size,
	variant = ButtonVariants.tertiary,
	value,
}: SelectProps) => {
	const [text, setText] = useState<string>(placeholderText);
	const [selectedValue, setSelectedValue] = useState(value);

	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value;
		setSelectedValue(value);
		onSelect(value);

		const selectElement = event.target;
		const selectedText = selectElement.options[selectElement.selectedIndex].text;
		setText(selectedText);
	};

	useEffect(() => {
		setSelectedValue(value);
		const selectedOption = options.find(option => option.value === value);
		setText(selectedOption ? selectedOption.name : placeholderText);
	}, [value, options, placeholderText]);

	return (
		<StyledLabel fullWidth={fullWidth} size={size} variant={variant}>
			<StyledSelect aria-labelledby={name} onChange={handleChange} value={selectedValue}>
				<option value="">{placeholderText}</option>
				{options.map(({ name, value }) => (
					<option key={value} value={value}>
						{name}
					</option>
				))}
			</StyledSelect>
			<Typography
				tight
				id={name}
				variant={TypographyVariant.labelMD}
				weight={FONT_WEIGHT.medium}
			>
				{text}
			</Typography>
			<StyledChevron aria-hidden="true" height="20" width="20" />
		</StyledLabel>
	);
};
