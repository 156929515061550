/**
 * Button Variants
 * @default primary
 */
export enum BadgeVariants {
	primary = "primary",
	secondary = "secondary",
	tertiary = "tertiary",
	primaryOutlined = "primaryOutlined",
	secondaryOutlined = "secondaryOutlined",
	tertiaryOutlined = "tertiaryOutlined",
}

/**
 * Button Sizes
 * @default MD
 */
export type BadgeSizes = "SM" | "MD";

export interface BadgeProps {
	size?: BadgeSizes;
	fullWidth?: boolean;
	variant?: BadgeVariants;
	withShadow?: boolean;
}
