/**
 * Button Variants
 * @default primary
 */
export enum ButtonVariants {
	primary = "primary",
	secondary = "secondary",
	tertiary = "tertiary",
	primaryWhite = "primaryWhite",
	secondaryWhite = "secondaryWhite",
}

export enum TextButtonVariants {
	primaryText = "primaryText",
	secondaryText = "secondaryText",
	tertiaryText = "tertiaryText",
}

/**
 * Button Sizes
 * @default XL
 */
export type ButtonSizes = "XL" | "LG" | "MD";

export interface ButtonProps {
	size?: ButtonSizes;
	fullWidth?: boolean;
	loading?: boolean;
	useMobileButtonBehaviour?: boolean;
	variant?: ButtonVariants;
}

export interface TextButtonProps {
	size?: ButtonSizes;
	loading?: boolean;
	useMobileButtonBehaviour?: boolean;
	variant?: TextButtonVariants;
}
