import { theme } from "./evernest";
import { TYPOGRAPHY } from "./typography";

export const BadgeSizeStyles = {
	SM: { ...TYPOGRAPHY.body.SM, height: "26px", padding: "0 10px" },
	MD: { ...TYPOGRAPHY.body.MD, height: "29px", padding: "1px 10px 0" },
};

export const BadgeVariantStyles = {
	primary: {
		background: theme.palette.freeze[1000],
		color: theme.palette.freeze[0],
	},
	secondary: {
		background: theme.palette.freeze[0],
		color: theme.palette.freeze[1000],
	},
	tertiary: {
		background: theme.palette.freeze[500],
		color: theme.palette.freeze[0],
	},
	primaryOutlined: {
		color: theme.palette.freeze[1000],
		border: `1px solid ${theme.palette.freeze[1000]}`,
	},
	secondaryOutlined: {
		color: theme.palette.freeze[1000],
		border: `1px solid ${theme.palette.freeze[200]}`,
	},
	tertiaryOutlined: {
		color: theme.palette.freeze[500],
		border: `1px solid ${theme.palette.freeze[500]}`,
	},
};
